import React from "react";
import SubCategoriesAdd from "../components/SubCategories/SubCategoriesAdd";

const AddSubCategories = () => {
  return (
    <div>
      <SubCategoriesAdd />
    </div>
  );
};

export default AddSubCategories;
