import React from "react";
import SubCategoriesList from "../components/SubCategories/SubCategoriesList";

const SubCategories = () => {
  return (
    <div>
      <SubCategoriesList />
    </div>
  );
};

export default SubCategories;
